import React from 'react';

type State = any;
interface CounterClassProps {
    time?: number,
    format?: string,
    finish?: () => void,
    noshifts?: boolean
}
class CounterClass extends React.Component<CounterClassProps, State> {
    now: any;

    timer: any;

    constructor(props: {}) {
      super(props);
      this.state = { time: {}, seconds: (props as any).time ? (props as any).time : 60 };
      this.timer = 0;
      this.startTimer = this.startTimer.bind(this);
      this.countDown = this.countDown.bind(this);
      this.now = new Date();
    }

    secondsToTime(secs: any) {
      const hours = Math.floor(secs / (60 * 60));
      const divisor_for_minutes = secs % (60 * 60);
      const minutes = Math.floor(divisor_for_minutes / 60);
      const divisor_for_seconds = divisor_for_minutes % 60;
      const seconds = Math.ceil(divisor_for_seconds);
      const obj = {
        h: hours,
        m: minutes,
        s: seconds,
      };
      return obj;
    }

    componentDidMount() {
      const timeLeftVar = this.secondsToTime(this.state.seconds);
      this.setState({ time: timeLeftVar });
      this.startTimer();
    }

    startTimer() {
      if (this.timer == 0 && this.state.seconds > 0) {
        this.timer = setInterval(this.countDown, 1000);
      }
    }

    countDown() {
      // Remove one second, set state so a re-render happens.
      const now = new Date();
      const delta = Math.floor((now.getTime() - this.now.getTime()) / 1000); // seconds elapsed since start
      const counter = (this.props as any).time ? (this.props as any).time : 60;
      const seconds = counter - delta;
      this.setState({
        time: this.secondsToTime(seconds),
        seconds,
      });
      //  Check if we're at 60.
      if (delta == counter) {
        clearInterval(this.timer);
        if ((this.props as any).finish !== undefined) {
          (this.props as any).finish();
        }
      }
    }

    render() {
      if (Math.sign((this.props as any).time) === -1) return '00:00:00';
      return (
        <>
          {(() => {
            const seconds = this.state.time.s;
            const minutes = this.state.time.m;
            const hours = this.state.time.h;
            if ((this.props as any).format === 'h:m:s') {
              const time = `${hours <= 9 ? '0' : ''}${hours} : ${minutes <= 9 ? '0' : ''}${minutes} : ${seconds <= 9 ? '0' : ''}${seconds}`;
              return (this.props as any).noshifts ? time.split(' ').join('') : time;
            }
            return seconds == 0 ? 60 : `${seconds <= 9 ? '0' : ''}${seconds}`;
          })()}
        </>
      );
    }
}
export default CounterClass;
