import React from 'react';
import { connect } from 'react-redux';
import './login.scss';
import './mobile/index.scss';
import { vipBg } from '@assets/img';
import {
  Container, Grid, Paper, TextField, NoSsr,
} from '@material-ui/core';
import Link from 'next/link';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { userActions } from '@redux/actions';
import Router from 'next/router';
import Head from 'next/head';
import { withTranslation } from '@i18n';
import Cookies from 'universal-cookie';
import {
  toast, ee, mailCensor, languageReturner, axios,
} from '../../helpers';
import {
  UnderHeader, Btn, CounterClass, NewCaptcha,
} from '../../components';
import { userService } from '../../services';

type State = any;

class Login extends React.Component<any, State> {
  static getInitialProps = async () => ({
    namespacesRequired: ['common'],
  });


  constructor(props: {}) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitCode = this.handleSubmitCode.bind(this);
    this.validateFields = this.validateFields.bind(this);
    this.updateState = this.updateState.bind(this);
    this.getContentByQuery = this.getContentByQuery.bind(this);
    
  }
  

  state = {
    email: '',
    password: '',
    submitted: false,
    showPassword: false,
    error: false,
    doubleFA: '',
    doubleFADisable: '',
    loadingDisable: false,
    loginStatus: false,
    emailValid: false,
    emailValidText: '',
    reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    passwordValid: false,
    passwordValidText: '',
    fa2Valid: false,
    fa2ValidText: '',
    fa2ValidDisable: false,
    fa2ValidTextDisable: '',
    resolved: false,
    resolvedValidText: '',
    showResend: false,
    content: 'main',
    accessToken: null,
  };

  updateState(key: any, value: any) {
    this.setState((prevState: any) => ({
      ...prevState,
      [key]: value,
    }));
  }
  

  getContentByQuery() {
    if ((Router as any)?.router?.query?.content === 'fa2') {
      const cookies = new Cookies();
      const accessToken = cookies.get('accessToken');
      const email = cookies.get('email');

      if (accessToken) {
        this.updateState('accessToken', accessToken);
        this.updateState('content', 'fa2');
        this.updateState('email', email);
      }
    }
  }

  isSocialMedia2fa() {
    if ((Router as any)?.router?.query?.content === 'fa2') {
      const cookies = new Cookies();
      const accessToken = cookies.get('accessToken');
      if (accessToken) {
        return true;
      }
    }
    return false;
  }

  openCaptcha() {
    this.setState((prevState: any) => ({
      ...prevState,
      submitted: true,
    }));
    const validated = this.validateFields();
    if (!validated) {
      toast('error', 'Необходимо правильно заполнить все поля');
      return false;
    }

    const captcha: HTMLElement = document.querySelector('.scaptcha-anchor-container') as HTMLElement;
    if (captcha) {
      captcha.click();
    }
  }

  handleSubmitCode(e: any) {
    const { t } = this.props;
    e.preventDefault();
    const { doubleFADisable } = this.state;
    this.setState((prevState: any) => ({
      ...prevState,
      loadingDisable: true,
    }));
    axios.post(`${process.env.REACT_APP_API_SERVER_URL}/api/2fa/disable `, {
      verification_code: doubleFADisable,
    }, {
      headers: {
        'X-localization': (this.props as any).language === 'ru' ? 'ru' : 'en',
      },
    })
      .then((data) => {
        if (data.status === 200) {
          const cookies = new Cookies();
          cookies.remove('2faFirst', { path: '/' });
          this.setState((prevState: any) => ({
            ...prevState,
            loadingDisable: false,
            content: 'fa2DisableRedirect',
          }));
        } else {
          this.setState((prevState: any) => ({
            ...prevState,
            loadingDisable: false,
          }));
          toast('error', t("Что-то пошло не так."));
        }
      });
  }

  handleSubmit(e: any) {
    if (e) {
      e.preventDefault();
    }

    this.setState((prevState: any) => ({
      ...prevState,
      loginStatus: true,
    }));
    const { email, password, doubleFA, accessToken } = this.state;
    const { dispatch, language, setEmailRedux } = this.props;
    const cookies = new Cookies();
    const options = {
      path: '/',
    };
    if (email) {
      cookies.set('email', email, options);
      dispatch(userActions.setEmail(email));
    }

    // Standard login
    if (email && password) {
      dispatch(userActions.login({ email, password, doubleFA })).then((res: any) => {
        userService.redirectAfterLogin({ language, res });
      }, (err: any) => {
        this.setState((prevState: any) => ({
          ...prevState,
          loginStatus: false,
        }));
      });
    }

    // Social login 2fa
    if (email && accessToken) {
      dispatch(userActions.fa2SocialLogin({ email, accessToken, doubleFA })).then((res: any) => {
        userService.redirectAfterLogin({ language, res });
      }, (err: any) => {
        this.setState((prevState: any) => ({
          ...prevState,
          loginStatus: false,
        }));
      });
    }
  }

  validateEmail(value: any = null) {
    // Email
    const { t } = this.props;
    const { reg } = this.state;
    const email = value !== null ? value : this.state.email;
    let validated = true;
    if (!reg.test(String(email).toLowerCase())) {
      this.setState((prevState: any) => ({
        ...prevState,
        emailValid: false,
        emailValidText: t('Введенный e-mail некорректен'),
      }));
      validated = false;
    } else {
      this.setState((prevState: any) => ({
        ...prevState,
        emailValid: true,
        emailValidText: '',
      }));
    }
    return validated;
  }

  validatePassword(value: any = null) {
    // Password
    const { t } = this.props;
    const password = value !== null ? value : this.state.password;
    let validated = true;
    if (password.length === 0) {
      this.setState((prevState: any) => ({
        ...prevState,
        passwordValid: false,
        passwordValidText: t('Введенный пароль не может быть пустым'),
      }));
      validated = false;
    } else {
      this.setState((prevState: any) => ({
        ...prevState,
        passwordValid: true,
        passwordValidText: '',
      }));
    }
    return validated;
  }

  validateCaptcha() {
    // Каптча
    const { t } = this.props;
    const { resolved } = this.state;
    let validated = true;
    if (!resolved) {
      this.setState((prevState: any) => ({
        ...prevState,
        resolved: false,
        resolvedValidText: t('Вы не прошли капчу'),
      }));
      validated = false;
    } else {
      this.setState((prevState: any) => ({
        ...prevState,
        resolved: true,
        resolvedValidText: '',
      }));
    }
    return validated;
  }

  validate2Fa(value: any = null) {
    // Password
    const { t } = this.props;
    const fa2 = value !== null ? value : this.state.doubleFA;
    let validated = true;
    if (fa2.length === 0) {
      this.setState((prevState: any) => ({
        ...prevState,
        fa2Valid: false,
        fa2ValidText: t('Введенный код не может быть пустым'),
      }));
      validated = false;
    } else {
      this.setState((prevState: any) => ({
        ...prevState,
        fa2Valid: true,
        fa2ValidText: '',
      }));
    }
    return validated;
  }

  validateFields() {
    let validated = true;

    if (this.isSocialMedia2fa()) return true;
    if (!this.validateEmail()) validated = false;
    if (!this.validatePassword()) validated = false;
    // if (!this.validateCaptcha()) validated = false;

    return validated;
  }
  componentWillReceiveProps(nextProps: any) {
    const cookies = new Cookies();
    const loginLang = cookies.get('loginLang');
    if (loginLang !== nextProps.language) {
      cookies.set('loginLang', nextProps.language, { path: '/' });
    }
  }

  componentDidMount() {
    ee.on('CloseResendEmail', () => {
      this.setState({ ...this.state, showResend: true });
    });
    ee.on('show2Fa', () => {
      this.setState((prevState: any) => ({
        ...prevState,
        content: 'fa2',
      }));
    });
    if ((Router as any).router.query.needAuthorization === 'true') {
      toast('error', 'Вам необходимо авторизоваться!');
    }
    if ((Router as any).router.query.email === 'false') {
      toast('error', 'В вашем профиле отсутствует email');
    }
    this.getContentByQuery();

    const { language } = this.props;
    const cookies = new Cookies();
    cookies.set('loginLang', language, { path: '/' });
  }

  render() {
    const { language, t, windowWidth } = this.props;
    const {
      email,
      password,
      submitted,
      resolved,
      resolvedValidText,
      doubleFA,
      loginStatus,
      emailValidText,
      passwordValidText,
      fa2ValidText,
      fa2ValidTextDisable,
      doubleFADisable,
      showResend,
      content,
      loadingDisable,
    } = this.state;

    const socialMediaLogin = (
      <div className="social-media-auth">
        <div className="form-group">
          <div className="login_label">{t('Или')}</div>
        </div>
        <div className="form-group">
          <Btn
            block
            href="/auth/google"
            color="red"
            className="login-button"
            size={windowWidth <= 1360 ? 'md' : 'lg'}
          >
            <i className="fab fa-google" />{t('Войти с помощью Google')}
          </Btn>
        </div>
        <div className="form-group">
          <Btn
            block
            href="/auth/facebook"
            color="blue-fb"
            className="login-button"
            size={windowWidth <= 1360 ? 'md' : 'lg'}
          >
            <i className="fab fa-facebook" />{t('Войти с помощью Facebook')}
          </Btn>
        </div>
      </div>
    );

    const main = (
      <Grid justify="space-between" container spacing={3}>
        <Grid item xs={12} sm={12} md={12}>
          <div className="login-form-text">
            <h2 className="login-title">{t('Вход')}</h2>
            {this.state.error && <div className="login_error">{t('Вы ввели неправельный логин или пароль')}</div>}
          </div>
          <div className="col-md-12 col-md-offset-12 login-form">
            <NoSsr>
              <form name="form" className="login_form" onSubmit={this.openCaptcha}>
                <div className={`form-group${submitted && !email ? ' has-error' : ''}`}>
                  <div className={`login_label${emailValidText ? ' has-error' : ''}`}>Email</div>
                  <TextField
                    helperText={emailValidText}
                    error={!!emailValidText}
                    variant="outlined"
                    type="text"
                    className="form-control login_input"
                    name="email"
                    value={email}
                    onChange={(e) => {
                      this.setState({
                        ...this.state,
                        email: e.target.value.split(' ').join('').trim().replace(/ +/g, ' '),
                      });
                      if (submitted) this.validateEmail(e.target.value);
                    }}
                  />
                </div>
                <div className={`form-group${submitted && !password ? ' has-error' : ''}`}>
                  <div className={`login_label${passwordValidText ? ' has-error' : ''}`}>{t('Пароль')}</div>
                  <FormControl className="login_password" variant="outlined">
                    <TextField
                      helperText={passwordValidText}
                      error={!!passwordValidText}
                      name="password"
                      variant="outlined"
                      id="outlined-adornment-password"
                      type={this.state.showPassword ? 'text' : 'password'}
                      value={this.state.password}
                      onChange={(e) => {
                        this.setState({
                          ...this.state,
                          password: e.target.value.split(' ').join('').trim().replace(/ +/g, ' '),
                        });
                        if (submitted) this.validatePassword(e.target.value.split(' ').join('').trim().replace(/ +/g, ' '));
                      }}
                      InputProps={{
                        endAdornment: (<InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => this.setState((prevState: any) => ({
                              ...prevState,
                              showPassword: !this.state.showPassword,
                            }))}
                            onMouseDown={(event) => {
                              event.preventDefault();
                            }}
                            edge="end"
                          >
                            {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>),
                      }}
                    />
                  </FormControl>
                </div>
                <div className="login-forgotPassword">
                  <Link
                    href="/forgot-password"
                    as={`${languageReturner(language)}/forgot-password`}
                  >
                    <a>{t('Забыли пароль?')}</a>
                  </Link>
                </div>
                <NoSsr>
                  <NewCaptcha
                    resolved={resolved}
                    resolvedValidText={resolvedValidText}
                    successFunc={() => {
                      this.updateState('resolved', true);
                      this.handleSubmit(false);
                    }}
                  />
                </NoSsr>
                <div className="form-group">
                  <Btn
                    block
                    component="a"
                    className="login-button"
                    loading={loginStatus}
                    size={windowWidth <= 1360 ? 'md' : 'lg'}
                    onClick={() => {
                      if (!resolved) {
                        this.openCaptcha();
                      } else {
                        this.handleSubmit(false);
                      }
                    }}
                  >
                    {t('Войти')}
                  </Btn>
                </div>
                <div className="login-accountNo">
                  {language !== 'ru' && (t('Нет аккаунта?'))}
                  <Link href="/register" as={`${languageReturner(language)}/register`}>
                    <a>{language === 'ru' ? t('Нет аккаунта?') : ` ${t('Зарегистрироваться')}`}</a>
                  </Link>
                </div>
              </form>
            </NoSsr>
          </div>
          {socialMediaLogin}
        </Grid>
      </Grid>
    );
    const fa2 = (
      <Grid justify="space-between" container spacing={3}>
        <Grid item xs={12} sm={12} md={12}>
          <a className="linkBack" href="#" onClick={() => {
            Router.push('/login', `${languageReturner(language)}/login`);
            this.updateState('content', 'main');
          }}><i className="fal fa-angle-left" />{t('Вернуться назад')}</a>
          <div className="login-form-text">
            <h2 className="login-2fa-title">{t('Проверка безопасности')}</h2>
          </div>
          <div className="login__2fa-text">
            {t('Для защиты аккаунта выполните следующую проверку')}
          </div>
          <div className="col-md-12 col-md-offset-12 login-form">
            <NoSsr>
              <form name="form" className="login_form" onSubmit={this.handleSubmit}>
                <div className={`form-group${submitted && !email ? ' has-error' : ''}`}>
                  <div
                    className={`login_label${emailValidText ? ' has-error' : ''}`}
                  >
                    {t('Код подтверждения Google:')}
                  </div>
                  <TextField
                    variant="outlined"
                    helperText={fa2ValidText}
                    error={!!fa2ValidText}
                    type="text"
                    className="form-control login_input"
                    name="doubleFA"
                    value={doubleFA}
                    onChange={(e: any) => {
                      const { value } = e.target;
                      if (value.length <= 6) {
                        this.setState({
                          ...this.state,
                          doubleFA: value.replace(/[^0-9]/g, ''),
                        });
                      }
                    }}
                  />
                </div>
                <div className="form-group">
                  <Btn
                    block
                    component="button"
                    type="submit"
                    className="login-button"
                    size={windowWidth <= 1360 ? 'md' : 'lg'}
                    loading={loginStatus}
                  >
                    {t('Отправить ')}
                  </Btn>
                </div>
                {/* <div className="login-accountNo"> */}
                  {/* <div className="login__2fa-link-text">{t('Нет доступа к Google Authenticator?')}</div> */}
                  {/* <div
                    className="login__2fa-link"
                    onClick={() => {
                      axios.post(`${process.env.REACT_APP_API_SERVER_URL}/api/2fa/send_code`, {
                        email,
                      }, {
                        headers: {
                          'X-localization': language === 'ru' ? 'ru' : 'en',
                        },
                      })
                        .then((data) => {
                          if (data.status === 200) {
                            this.setState({ ...this.state, content: 'fa2Disable' });
                            toast('success', 'Код отключение 2fa отправлен на почту');
                          } else if ((data as any).response.status === 429) {
                            toast('error', 'Превышен лимит запросов. Попробуйте, пожалуйста, позже');
                          } else {
                            toast('error', 'Что-то пошло не так.');
                          }
                        });
                    }}
                  >
                    {t('Отключить двухфакторную аутентификацию')}
                  </div> */}
                {/* </div> */}
              </form>
            </NoSsr>
          </div>
        </Grid>
      </Grid>
    );
    const fa2Disable = (
      <Grid justify="space-between" container spacing={3}>
        <Grid item xs={12} sm={12} md={12}>
          <div
            className="login__2fa-link-back"
            onClick={() => {
              this.setState((prevState: any) => ({
                ...prevState,
                content: 'fa2',
              }));
            }}
          >
            <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1.25 9.66666L5.33333 5.41039L1.25 0.916657"
                stroke="#272727"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            {t('Вернуться назад')}
          </div>
          <div className="login-form-text">
            <h2 className="login-2fa-title">{t('Отключение 2FA')}</h2>
          </div>
          <div className="login__2fa-text">
            {t('Для отключения двухфакторной аутентификации введите код из сообщения на вашей почте')}
          </div>
          <div className="col-md-12 col-md-offset-12 login-form">
            <NoSsr>
              <form name="form" className="login_form" onSubmit={this.handleSubmitCode}>
                <div className={`form-group${submitted && !email ? ' has-error' : ''}`}>
                  <div
                    className={`login_label_2fa-disable login_label${emailValidText ? ' has-error' : ''}`}
                  >
                    {`${t('Введите 5-значный код, полученный на')} ${mailCensor(email)}`}
                  </div>
                  <TextField
                    variant="outlined"
                    helperText={fa2ValidTextDisable}
                    error={!!fa2ValidTextDisable}
                    type="text"
                    className="form-control login_input login__2fa-disable-field"
                    name="doubleFADisable"
                    value={doubleFADisable}
                    onChange={(e) => {
                      if (e.target.value.length <= 5) {
                        this.setState({
                          ...this.state,
                          doubleFADisable: e.target.value.split(' ').join('').trim().replace(/ +/g, ' '),
                        });
                      }
                    }}
                  />
                </div>
                <div className="login__2fa-resend-block">
                  <div className="login__2fa-resend-text">{t('Не получили код?')}</div>
                  <div className="login__2fa-resend-counter">
                    {showResend === false ? (
                      <>
                        {t('Отправить снова')}
                        {' '}
                        (
                        <NoSsr><CounterClass finish={() => ee.emit('CloseResendEmail')} /></NoSsr>
                        {t('секунд')}
                        )
                      </>
                    ) : (
                      <div
                        className="login__2fa-resend-counter-link login__2fa-resend-counter-link-blue"
                        onClick={() => {
                          this.setState({ ...this.state, showResend: false });
                          axios.post(`${process.env.REACT_APP_API_SERVER_URL}/api/2fa/send_code`, {
                            email,
                          }, {
                            headers: {
                              'X-localization': language === 'ru' ? 'ru' : 'en',
                            },
                          })
                            .then((data) => {
                              if (data.status === 200) {
                                toast('success', 'Код отключение 2fa отправлен на почту');
                              } else if ((data as any).response.status === 429) {
                                toast('error', 'Превышен лимит запросов. Попробуйте, пожалуйста, позже');
                              } else {
                                toast('error', t("Что-то пошло не так."));
                              }
                            });
                        }}
                      >
                        {t('Отправить снова')}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <Btn
                    block
                    component="button"
                    className="login-button"
                    size={windowWidth <= 1360 ? 'md' : 'lg'}
                    loading={loadingDisable}
                  >
                    {t('Отправить ')}
                  </Btn>
                </div>
                <div className="login-accountNo login-accountNo_2fa-disable">
                  <div className="login__2fa-link-text">
                    {t('Если вы забыли пароль или потеряли доступ к устройствам, свяжитесь с нашей')}
                    <div className="login__2fa-link">
                      <Link href="https://cryptouniverse.freshdesk.com/support/tickets/new">
                        <a
                          target="_blank"
                        >
                          {t('Службой поддержки')}
                        </a>
                      </Link>
                    </div>
                  </div>
                </div>
              </form>
            </NoSsr>
          </div>
        </Grid>
      </Grid>
    );
    const fa2DisableRedirect = (
      <Grid justify="space-between" container spacing={3}>
        <Grid item xs={12} sm={12} md={12}>
          <div className="login-form-text">
            <h2 className="login-2fa-title">{t('Отключение 2FA')}</h2>
          </div>
          <div className="login__2fa-text">
            {t('Двухфакторная аутентификация выключена. Теперь вы можете войти, используя свою почту и пароль.')}
          </div>
          <div className="col-md-12 col-md-offset-12 login-form">
            <NoSsr>
              <form name="form" className="login_form">
                <div className="form-group">
                  <Btn
                    block
                    component="button"
                    className="login-button"
                    size={windowWidth <= 1360 ? 'md' : 'lg'}
                    onClick={() => this.setState({ ...this.state, content: 'main' })}
                  >
                    {t('Войти')}
                  </Btn>
                </div>
              </form>
            </NoSsr>
          </div>
        </Grid>
      </Grid>
    );

    return (
      <div className="Login">
        <Head>
          <title>{t('Облачный майнинг - Вход | CryptoUniverse')}</title>
          <meta
            name="description"
            content={t('На данной странице вы можете осуществить вход в свой аккаунт, используя логин или пароль.')}
          />
        </Head>
        <UnderHeader style={{ backgroundImage: `url(${vipBg})` }}>
          <Container className="login-container">
            <div className="login-block">
              {(() => {
                let block;
                switch (content) {
                  case 'main':
                    block = main;
                    break;
                  case 'fa2':
                    block = fa2;
                    break;
                  case 'fa2Disable':
                    block = fa2Disable;
                    break;
                  case 'fa2DisableRedirect':
                    block = fa2DisableRedirect;
                    break;
                  default:
                    return null;
                }
                return (windowWidth < 640 ? block : (
                  <Paper className="paper pa4 pt0 overflow-hidden">
                    {block}
                  </Paper>
                ));
              })()}
            </div>
          </Container>
        </UnderHeader>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { language, windowWidth } = state.info;
  return {
    language,
    windowWidth,
  };
}

export default withTranslation('common')(connect(mapStateToProps)(Login));
